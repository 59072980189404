<template>
  <RsModal
    class="modal-confirm-reload"
    modal-window-classes="modal-window-edit-driver"
    @dismiss="confirmAndClose"
  >
    <RsHeadline variant="h2">
      {{ $t('modals.confirmReloadPage.title') }}
    </RsHeadline>

    <i18n-t
      tag="div"
      keypath="modals.confirmReloadPage.description"
      class="mt-4 mb-8 text-gray-600"
    >
      <template #timeout>
        <span class="font-bold">{{ countdown }}s</span>
      </template>
    </i18n-t>

    <div class="flex justify-end">
      <div class="w-1/2">
        <RsButton class="w-full" @click="confirmAndClose">
          <span class="text-white">{{ $t('common.continue') }}</span>
        </RsButton>
      </div>
    </div>
  </RsModal>
</template>

<script setup lang="ts">
import delay from "lodash/delay"

const TIMEOUT_STARTING_SECOND = 60

defineProps({
  newLanguage: {
    type: String,
    required: true,
  },
})

const router = useRouter()

const countdown = ref(TIMEOUT_STARTING_SECOND)
const counterInterval = ref(null)

const confirmAndClose = () => {
  router.go()
}

onMounted(() => {
  counterInterval.value = setInterval(() => countdown.value = countdown.value - 1, 1000)

  // auto reload after 60 seconds
  delay(() => {
    clearInterval(counterInterval.value)
    router.go()
  }, TIMEOUT_STARTING_SECOND * 1000)
})
</script>

<style scoped>
.modal-confirm-reload {
  @apply items-center md:items-start;
}
:deep(.modal-window-edit-driver) {
  @apply !w-11/12 !max-w-md !h-auto !rounded-lg;
}

</style>